#mini-panel-4_col {
  @extend .container;
  padding-top: rem(65px);
  padding-bottom: rem(65px);
  overflow: hidden;

  @include media-breakpoint-up('md') {
    padding-top: rem(70px);
  }

  > .panel-panel > div {
    @include lost-row();
    margin-bottom: -20px;
  }

  .panel-pane {
    lost-column: 1/1 1 20px;
    margin-bottom: 20px;

    @include media-breakpoint-up('md') {
      lost-column: 1/2 2 20px;
    }

    @include media-breakpoint-up('xl') {
      lost-column: 1/4;
    }
  }
}

.pane-bundle-fieldable-panels-pane {
  .group-left {
    display: flex;
    align-items: center;
    margin-bottom: rem(20px);

    .field-name-field-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      min-width: 62px;
      min-height: 54px;
    }

    .block-title {
      margin: 0;
      font-size: rem(18px);
      line-height: (23 / 18);
    }
  }

  .field-name-field-readmore {
    margin-top: .5rem;
  }
}
