$primary:             #00984d;

$enable-shadows:            true;
$enable-gradients:          true;

$enable-grid-classes:       false;
$enable-print-styles:       false;

$spacer:   1.875rem;

$body-bg:    #fff;
$body-color: #4d4d4d;

$link-hover-color:      #d49800;
$link-hover-decoration: none;

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1260px
);

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1230px
);

$grid-gutter-width:     40px;

$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base:       "Open Sans", $font-family-sans-serif;
$font-size-base: .9375rem;
$line-height-base: 1.6;

$h1-font-size: 2rem;
$h2-font-size: 1.3125rem;
$h3-font-size: 1.1875rem;
$h4-font-size: 1rem;
$h5-font-size: 1rem;
$h6-font-size: 1rem;

$headings-margin-bottom: 1.2rem;
$headings-font-family:   'Open Sans', sans-serif;
$headings-font-weight:   600;
$headings-line-height:   1.1;

$lead-font-size:   .8125rem;
$lead-font-weight: 400;

$table-accent-bg:             #fffcf8;
$table-hover-bg:              #f5f3eb;
$table-border-color:          #f3ece2;

$input-btn-padding-y:                  .71875rem;
$input-btn-padding-x:                  1.59375rem;
$input-btn-line-height:     1.25;

$btn-box-shadow:                none;
$btn-focus-width:               none;
$btn-focus-box-shadow:          none;

$btn-border-radius:              4px;

$input-color:                    $body-color;
$input-border-color:             #cfe0d8;
$input-box-shadow:               none;

$input-focus-border-color:             #dceee5;
$input-focus-box-shadow:         rgba(220,238,229,.6);

$input-placeholder-color:        #7c9487;

$dropdown-min-width:             200px;
$dropdown-padding-y:             0;
$dropdown-spacer:                2px;
$dropdown-bg:                    #ffffff;
$dropdown-border-color:          #f0ece2;
$dropdown-border-width:          0;
$dropdown-divider-bg:            #c9ecdb;
$dropdown-box-shadow:            0px 2px 6.44px 0.56px rgba(0, 0, 0, 0.1);

$dropdown-link-color:            #36835d;
$dropdown-link-hover-color:      #36835d;
$dropdown-link-hover-bg:         #e1f2eb;

$dropdown-link-active-color:     #d49800;
$dropdown-link-active-bg:        #fff9f1;

$nav-item-margin:               0;
$nav-item-inline-spacer:        0;
$nav-tabs-link-active-color:    $link-hover-color;

$navbar-padding-y:                  0;
$navbar-padding-x:                  0;

$navbar-light-color:                #36835d;
$navbar-light-hover-color:          #36835d;
$navbar-light-active-color:         #d49800;

$breadcrumb-padding-y:          0;
$breadcrumb-padding-x:          0;

$breadcrumb-bg:                 transparent;
$breadcrumb-divider-color:      #676767;
$breadcrumb-active-color:       #d49800;
$breadcrumb-divider:            ">";

$carousel-control-width:                      40px;
$carousel-control-opacity:                    .2;
$carousel-indicator-width:                    10px;
$carousel-indicator-height:                   10px;
$carousel-indicator-active-bg:                #000;
