.cms-components {
  background: #fff9f1;
  padding: rem(60px) 0;

  .panel-inner {
    @extend .container;
  }

  .pane-content {
    @include media-breakpoint-up('lg') {
      display: flex;
    }

    .left {
      text-align: center;

      @include media-breakpoint-up('sm') {
        min-width: 318px;
      }

      @include media-breakpoint-up('lg') {
        padding-top: 20px;
        margin-right: 80px;
      }

      @media screen and (min-width: 1400px) {
        min-width: 393px;
        margin-left: -80px;
      }

      img {
        height: auto !important;
      }
    }

    .right {
      h3 {
        color: #ffae00;
        font-size: rem(24px);
        line-height: (34 / 24);
        letter-spacing: .01em;
        margin-bottom: rem(24px);
      }

      ul {
        list-style: none;
        padding: 0;
        letter-spacing: .015em;
      }

      li {
        &:before {
          font-awesome: angle-right;
          font-size: rem(16px);
          color: #01c464;
          margin-right: .6em;
        }

        + li {
          margin-top: rem(16px);
        }
      }
    }
  }
}
