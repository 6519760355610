.cms-header {
  &__row-1 {
    background: url("../img-src/images/cms-header-bg.jpg") 0 0 / cover no-repeat;
    padding: 30px 0;
    letter-spacing: .06em;

    > .inner {
      background: rgba(0, 0, 0, .45);
      padding: 60px 0;
    }

    .row {
      justify-content: space-around;
      align-items: center;

      @include media-breakpoint-up('lg') {
        flex-wrap: nowrap;
      }
    }

    .col-1, .col-2 {
      flex-shrink: 0;
    }

    .col-1 {
      width: 100%;
      text-align: center;

      @include media-breakpoint-up('md') {
        text-align: left;
        width: auto;
        margin-right: rem(40px);
      }

      @include media-breakpoint-up('lg') {
        margin-right: rem(20px);
        border-right: 1px solid #605a5c;
        padding-right: rem(20px);
        padding-top: rem(30px);
        padding-bottom: rem(30px);
      }

      @include media-breakpoint-up('xl') {
        margin-right: rem(40px);
        padding-right: rem(40px);
      }
    }

    .col-2 {
      margin-right: rem(8px);
      margin-top: rem(40px);

      @include media-breakpoint-up('md') {
        margin-top: 0;
      }
    }

    .col-3 {
      margin-top: rem(40px);

      @include media-breakpoint-up('md') {
        margin-top: rem(80px);
      }

      @include media-breakpoint-up('lg') {
        margin-top: 0;
      }

      @media screen and (min-width: 1400px) {
        margin-bottom: (0 - 95px - 30px - 60px);
        margin-right: -104px;
      }
    }

    ul {
      list-style: none none;
      padding: 0;
      font-weight: 300;
      color: #DDD;
      font-size: rem(18px);
      text-shadow: 0 3px 5.64px rgba(0, 0, 0, 0.2);

      li + li {
        margin-top: rem(9px);
      }
    }

    h2 {
      font-size: rem(26px);
      font-weight: 300;
      text-transform: uppercase;
      color: #f1f1f1;
      text-shadow: 0 3px 5.64px rgba(0, 0, 0, 0.2);
      margin: 0;
      padding-bottom: .5rem;

      .big {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(70px);
        color: #ffae00;
        letter-spacing: 0.18em;
        font-weight: 600;
        margin-bottom: rem(2px);
      }

      svg {
        margin-right: rem(26px);
      }
    }
  }

  &__row-2 {
    background: #eafdf4;
    padding: rem(33px) 0 rem(37px);
    letter-spacing: .02em;

    .row {
      justify-content: space-around;
      align-items: center;

      @include media-breakpoint-up('lg') {
        justify-content: flex-start;
      }
    }

    .btn {
      min-width: rem(260px);
      font-size: rem(18px);
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);

      @include media-breakpoint-up('sm') {
        margin-right: rem(40px);
      }
    }

    .readmore {
      font-size: rem(18px);
      text-decoration: underline;
      margin: rem(20px) 0 0 0;

      @include media-breakpoint-up('sm') {
        margin-top: 0;
      }

      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }

}
