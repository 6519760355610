.btn {
  font-size: rem(14px);
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}

.btn-primary {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
  transition: all .2s ease-in-out;

  @include hover-focus() {
    border-color: #d39602;
    background-color: #f5b301;
  }

  &:focus {
    outline: none;
  }
}

.btn-light {
  text-shadow: none;
  background: #FFF;
  border: 2px solid #0B734C;

  &:hover, &:focus {
    border-color: #0B734C;
  }
}
