//@mixin lost-container() {
//  @if ($adaptive-grid) {
//    @include media-breakpoint-up('xs') {
//      lost-center: 240px ($grid-gutter-width-base / 2) no-flex;
//      min-width: 240px;
//    }
//    @each $breakpoint, $container-max-width in $container-max-widths {
//      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
//        lost-center: $container-max-width ($grid-gutter-width-base / 2) no-flex;
//      }
//    }
//  } @else {
//    //width: 100%;
//    lost-center: 1200px ($grid-gutter-width-base / 2) no-flex;
//    width: 1200px;
//  }
//}

@mixin lost-row() {
  lost-flex-container: row;
}

.row {
  lost-flex-container: row;
}
