.panels-page--cms {
  .breadcrumb-wrap {
    display: none;
  }

  #page-header {
    margin-bottom: 0;
    box-shadow: none;
  }

  main {
    margin-top: 0;
  }

  .main-container {
    @each $breakpoint in map-keys($container-max-widths) {
      @include media-breakpoint-up($breakpoint) {
        padding: 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  #mini-panel-footer_v2_0 {
    border-top-color: #0b6c48;
  }
}
