.pane-studio {
  padding-top: rem(80px);
  padding-bottom: rem(80px);

  .pane-title {
    font-size: rem(24px);
    color: #ffae00;
  }

  .left {
    text-align: center;
  }

  .right {
    padding-top: 24px;
  }

  @include media-breakpoint-up('md') {
    .pane-content {
      @include lost-row();
      align-items: stretch;
    }

    .left {
      lost-column: 1/3;
    }

    .right {
      lost-column: 2/3;
      display: flex;
      flex-direction: column;

      p:last-child {
        margin-top: auto;
      }
    }
  }
}
