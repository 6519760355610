body.node-type-partner {
  #page-header {
    margin-bottom: 0;
  }

  .print-link {
    display: none;
  }
}

.node-partner {
  .group-header {
    position: relative;
    min-height: (100vw / 1903 * 417);
    padding: 37px 0 20px 0;
    color: #FFF;
    font-size: 20px;

    .field-name-field-image img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    .container {
      position: relative;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .field-name-title {
        width: 100%;
      }

      .field-name-field-header-text {
        margin-right: 16px;

        li {
          margin-bottom: 1rem;
        }
      }

      .field-name-field-link, .paragraphs-items {
        margin-top: 10px;
        width: 100%;
        text-align: center;

        .btn {
          font-size: 18px;
        }
      }
    }

    .page-title {
      text-align: center;
      font-size: 34px;
      color: #FFF;
    }

    .paragraphs-item-button:last-child {
      margin-bottom: 0;
    }
  }

  .paragraphs-item-text {
    padding: 37px 0 43px 0;
  }

  .paragraphs-item-button {
    margin-bottom: 43px;
  }

  @include media-breakpoint-down('lg') {
    table, tbody, td, th {
      display: block;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
    }
    td {
      flex-grow: 1;
      margin: 5px;
      width: auto !important;
    }
  }
}

#block-views-partners-slider-block {
  margin-bottom: $spacer * 2;
}

.optionset-parner-logos {
  a {
    display: block;
    width: 180px;
    margin: 0 10px;
  }
}

.paragraphs-item-button {
  text-align: center;
}

#page-modal {
  .modal-header {
    padding-bottom: 0;
    border-bottom: none;
  }
}
