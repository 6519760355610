.block-webform {
  border: 1px solid #f8eee1;
  background: #fff9f1;
  padding: rem(24px) rem(19px) rem(19px);

  .block-title {
    text-align: center;

    .fa {
      font-size: rem(19px);
      color: #e8a600;
    }
  }

  .form-group {
    margin-bottom: rem(10px);
  }

  .form-control {
    border-radius: 2px;
  }

  .form-actions {
    margin-top: rem(20px);
    text-align: center;
  }
}
