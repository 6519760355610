.cms-descr {
  text-align: center;
  letter-spacing: .015em;
  padding-top: rem(60px);
  padding-bottom: rem(60px);
  justify-content: space-around;
  margin-bottom: rem(-40px);

  &__block {
    margin-bottom: rem(40px);
    width: 100%;

    @include media-breakpoint-up('sm') {
      lost-column: 1/2;
    }

    @include media-breakpoint-up('md') {
      lost-column: 1/3;
    }

    @include media-breakpoint-up('xl') {
      lost-column: 1/3 3 90px;
    }
  }

  .icon {
    min-height: 74px;
    margin-bottom: rem(20px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3 {
    letter-spacing: 0;
    font-size: rem(18px);
    margin-bottom: rem(12px);
  }
}
