#page-header {
  //@include container-break();
  box-shadow: 0 2px 6.44px 0.56px rgba(0, 0, 0, 0.1);
  margin-bottom: rem(24px);
  text-align: center;

  .row {
    align-items: center;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;

    @include media-breakpoint-up('lg') {
      margin-left: $grid-gutter-width / -2;
      margin-right: $grid-gutter-width / -2;
    }
  }

  .col {
    @include make-col-flex(20px);

    @include media-breakpoint-up('lg') {
      @include make-col-flex();
    }
  }
}

.panel-slider #page-header {
  margin-bottom: 0;
}
