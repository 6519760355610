.page-frontpage {
  .page-header {
    @extend .container;
  }
}

#komtet-bn {
  background-size: cover;
  padding: 40px 0;

  .row {
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-breakpoint-up('lg') {
      flex-wrap: nowrap;
    }
  }

  h2 {
    color: #E9A600;
    font-size: 48px;
  }

  ul {
    list-style: none none;
    color: #1D1D1B;
    padding-left: 0;
  }

  a.btn {
    min-width: 300px;
  }

  .col {
    text-align: center;
    flex-grow: 1;
  }

  .col:last-child {
    @include media-breakpoint-up('lg') {
      order: 1;
      text-align: left;
    }
  }

  .col:first-child {
    @include media-breakpoint-up('lg') {
      order: 2;
      text-align: right;
    }
  }
}
