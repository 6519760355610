figure.scheme {
  margin-top: 2rem;

  figcaption {
    font-size: rem(14px);
    margin-bottom: 3rem;

    .left {
      margin-bottom: 1rem;
    }

    @include media-breakpoint-up('xl') {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .left {
        margin-bottom: 0;
      }
    }

    h3 {
      font-weight: bold;
      font-size: rem(16px);
      color: #0d495e;
      margin-bottom: .5rem;
    }

    table {
      caption {
        caption-side: top;
        padding: 0;
      }

      td {
        padding: .5rem 0;
        vertical-align: middle;

        svg {
          display: block;
          margin: 0 auto;
        }

        &:first-child {
          text-align: center;
          padding-right: 1rem;
          width: 5rem;
        }
      }
    }

    .right {
      h3 {
        margin-bottom: 1rem;
      }
    }

    dl {
      margin: 0;

      dt, dd {
        display: inline;
        padding: 0.5rem 0;
      }

      dd {
        &:before {
          content: ' ';
        }

        &:after {
          content: '';
          display: block;
          height: .75rem;
        }
      }
    }
  }

  .scheme__img {
    width: 100%;
    height: auto;
  }
}
