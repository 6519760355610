.pane-current-13 {
  padding-top: rem(60px);
  padding-bottom: rem(60px);

  .pane-title {
    color: #ffae00;
    font-size: rem(24px);
    margin-bottom: rem(60px);
  }
}

.pane-slider-companion-panel-pane-1 {
  display: none;
  background: #fff9f1;

  @include media-breakpoint-up('sm') {
    display: block;
  }

  .panel-inner {
    @extend .container;
  }

  .view-content {
    padding-left: 60px;
    padding-right: 60px;

    @media screen and (min-width: 1400px) {
      margin: 0 -60px;
    }
  }

  .views-row {
    padding: 0 ($grid-gutter-width / 2);
    cursor: pointer;

    &.slick-current {
      .views-field-field-image {
        border-bottom-color: #ffae00;
      }
    }

    .views-field-field-image {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 160px;
      border-bottom: 3px solid transparent;
      transition: border-bottom-color .2s ease-in-out;

      &:hover, &:focus {
        border-bottom-color: #ffd988;
      }
    }
  }

  .slider-arrow {
    background-color: transparent;
  }
}
