@if $enable-slicknav {
  #page-wrap .slicknav_menu {
    text-align: center;
    display: none;
    font-size: $font-size-base;
    box-sizing: border-box;
    background: transparent none;
    //text-transform: uppercase;
    //font-weight: bold;
    position: absolute;
    top: 0;
    right: rem(-10px);
    border-radius: 5px;
    z-index: 9999;

    &:nth-child(2) {
      top: 120px;
    }

    #admin-menu-shortcuts-responsive {
      display: none;
    }

    a {
      color: $primary !important;
      text-decoration: none;
    }

    .slicknav_btn {
      position: relative;
      display: block;
      vertical-align: middle;
      cursor: pointer;
      overflow: hidden;
      background: transparent none;
      //background-image: linear-gradient(to top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
      text-decoration: none;
      //height: 50px;
      padding: rem(10px);
      //border: 1px solid $btn-primary-border;

      &.slicknav_open {
        background: #f5b301;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);

        .slicknav_icon {
          .slicknav_icon-bar {
            background: #FFF;
          }
        }
      }

      &:not(.slicknav_open):hover, &:not(.slicknav_open):focus {
        .slicknav_icon {
          .slicknav_icon-bar {
            background: #f5b301;
          }
        }
      }

      .slicknav_menutxt {
        display: none;
        line-height: rem(50px);
        padding: rem(25px);
      }

      .slicknav_icon {
        display: block;

        .slicknav_icon-bar {
          display: block;
          margin: 0 0 0 auto;
          width: rem(40px);
          height: rem($ham-height);
          //border-radius: 1px;
          //box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
          background-color: #1bae77;

          & + .slicknav_icon-bar {
            margin-top: rem($ham-space);
          }
        }
      }

      .slicknav_no-text {
        margin: 0;
      }
    }

    .slicknav_nav {
      max-height: 80vh;
      min-width: 15rem;
      overflow: auto;
      margin: 0;
      padding: 10px 15px;
      background: #fff9f1;
      //background: linear-gradient(to right, #00264b, #004588);
      list-style: none;
      border: 1px solid $btn-border-width;
      border-top: 0;

      li {
        text-align: left;
      }

      a {
        padding: 5px 0;
        position: relative;
      }

      ul {
        display: block;
      }

      li {
        display: block;
      }

      .slicknav_arrow {
        font-size: 0.7em;
        margin: 0 0 0 0.4em;
        vertical-align: 20%;
      }

      .slicknav_item {
        cursor: pointer;

        a {
          display: inline;
        }
      }

      .slicknav_row {
        display: block;
      }

      a {
        display: block;
      }

      .slicknav_parent-link a {
        display: inline;
      }
    }
  }

  .slicknav_brand {
    float: left;
  }
}
