.field-name-post-date {
  color: #818282;
  font-size: rem(12px);
  white-space: nowrap;

  @include media-breakpoint-up('xl') {
    font-size: rem(11px);
  }
}

.field-name-node-link {
  margin-top: $spacer / 3;
  font-size: rem(14px);

  a {
    display: block;
  }
}

.node-news.node-teaser {
  .group-middle {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;

    @include media-breakpoint-up('xl') {
      flex-wrap: nowrap;
      align-items: flex-end;
      justify-content: space-between;
    }

    border-bottom: 1px solid #c9ecdb;
    padding: rem(10px) 0;
    margin-bottom: rem(6px);

    .group-left {
      flex-basis: 100%;
      order: 2;

      @include media-breakpoint-up('xl') {
        order: 1;
        margin-right: $spacer;
      }
    }

    .group-right {
      order: 1;
      margin-bottom: rem(2px);

      @include media-breakpoint-up('xl') {
        order: 2;
        margin-bottom: 0;
      }
    }
  }

  .node-title {
    margin: 0;
    line-height: 1.4;
  }
}

.view-id-news.view-display-id-block_2 {
  .view-content {
    ul {
      border: 1px solid #c9ecdb;
      padding: 0;
      list-style: none;
    }

    li + li {
      border-top: 1px solid #c9ecdb;
    }

    a {
      display: block;
      padding: .8125rem 1.25rem;

      &.active {
        color: #d49800;
      }
    }
  }

  .view-footer {
    margin-top: $spacer / 2;
    text-align: center;
  }
}
