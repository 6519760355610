#block-panels-mini-demo-mini {
  //@include container-break();

  background: #eafdf4;
  padding-top: rem(63px);
  padding-bottom: rem(67px - 21);

  .block-content {
    @extend .container;
  }

  .panel-2col {
    @include lost-row();
    align-items: center;

    @include media-breakpoint-up('lg') {
      align-items: flex-start;
    }
  }

  .panel-col-first {
    text-align: center;

    lost-column: 1/1;

    @include media-breakpoint-only('md') {
      lost-column: 7/12;
    }

    @include media-breakpoint-up('lg') {
      lost-column: 9/12;
      text-align: left;

      p:first-child {
        float: left;
        margin-right: $grid-gutter-width;
        margin-bottom: 0;
      }
    }
  }

  .panel-col-last {
    lost-column: 1/1;
    text-align: center;
    margin-top: $spacer;

    @include media-breakpoint-only('md') {
      lost-column: 5/12;
    }

    @include media-breakpoint-up('md') {
      margin-top: 0;
    }

    @include media-breakpoint-up('lg') {
      text-align: left;
      lost-column: 3/12;
    }
  }

  h4 {
    font-size: rem(21px);
    line-height: (30 / 21);
    font-weight: normal;
  }
}
