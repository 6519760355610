.pane-demo-front {
  //@include container-break();
  padding: rem(65px) 0 rem(70px);
  background: #eafdf4;

  .pane-content {
    @extend .container;
    position: relative;

    @include media-breakpoint-up('lg') {
      min-height: 280px;

      p {
        padding-right: 480px;
      }
    }

    @include media-breakpoint-up('xl') {
      min-height: 280px;
      padding-right: 480px;

      p {
        padding-right: 180px;
      }
    }

    &:before {
      content: '';
      display: block;
      background: url('../img-src/images/responsive.png') no-repeat;
      width: width('../img-src/images/responsive.png');
      max-width: 100%;
      background-size: contain;
      margin: 0 auto $spacer;
      height: calc((100vw - #{$grid-gutter-width}) * 338 / 628);

      @include media-breakpoint-only('sm') {
        height: (map-get($container-max-widths,'sm') - $grid-gutter-width) * 338 / 628;
      }

      @include media-breakpoint-only('md') {
        height: (map-get($container-max-widths,'md') - $grid-gutter-width) * 338 / 628;
      }

      @include media-breakpoint-up('lg') {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 460px;
        height: 248px;
        margin: 0;
      }

      @include media-breakpoint-up('xl') {
        position: absolute;
        top: 0;
        right: -50px + $grid-gutter-width;
        width: width('../img-src/images/responsive.png');
        height: height('../img-src/images/responsive.png');
      }
    }

    a.btn {
      margin-top: rem(24px);
    }

    @include media-breakpoint-down('sm') {
      p:last-child {
        text-align: center;
      }

      a.btn {
        margin: 0;
      }
    }
  }

  h2 {
    font-weight: normal;
    font-size: rem(32px);
    color: #e8a600;
    margin-bottom: 2rem;

    @include media-breakpoint-down('sm') {
      font-size: rem(21px);
      margin-bottom: 1rem;
    }
  }
}
