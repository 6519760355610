.pane-bundle-slider .field-name-field-slides .field-items {
  @include media-breakpoint-up('sm') {
    padding: 0 60px;
  }

  @media screen and (min-width: 1400px) {
    margin: 0 -60px;
  }
}
.slick-arrow {
  background: rgba(#fff, .3);
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0;
  width: 50px;
  height: 50px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  transition: background .2s ease-in-out;
  z-index: 500;

  svg {
    display: block;
  }

  &:focus {
    outline: none;
  }

  &:hover, &:focus {
    background: rgba(#efd49c, .3);
  }
}
.slick-prev {
  left: 0;
}
.slick-next {
  right: 0;
}
.flexslider {
  margin-bottom: 0;
  border: 0;

  a:focus {
    outline: none;
  }
}
.flex-control-nav {
  bottom: 0;
  z-index: 30;
}
.flex-direction-nav a {
  line-height: 1;
}
.pane-slider {
  margin-bottom: $spacer;

  @include media-breakpoint-up('xl') {
    margin-bottom: 0;
  }
}
