.breadcrumb-wrap {
  text-align: right;
  margin-bottom: $spacer;
  @include clearfix();

  a {
    color: #676767;
  }

  .breadcrumb {
    float: right;
  }
}

.breadcrumb-item + .breadcrumb-item:before {
  font-awesome: angle-right;
}
