@mixin resp-bg($image-name, $image-extension, $breakpoints: $grid-breakpoints) {
  background-image: url('../img-src/images/responsive/#{$image-name}.#{$image-extension}');
  background-size: cover;

  @media only screen and (min-resolution: 192dpi), // IE9-11 don't support dppx
  only screen and (min-resolution: 2dppx) { // Standardized
    background-image: url('../img-src/images/responsive/#{$image-name}@2x.#{$image-extension}');
  }

  @each $breakpoint-name, $breakpoint-size in $breakpoints {
    @media only screen and (min-width: $breakpoint-size) {
      background-image: url('../img-src/images/responsive/#{$image-name}-#{$breakpoint-name}.#{$image-extension}');
    }

    @media only screen and (min-resolution: 192dpi) and (min-width: $breakpoint-size), // IE9-11 don't support dppx
    only screen and (min-resolution: 2dppx) and (min-width: $breakpoint-size) { // Standardized
      background-image: url('../img-src/images/responsive/#{$image-name}-#{$breakpoint-name}@2x.#{$image-extension}');
    }
  }
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin limit-lines($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin make-col-flex($gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}

@mixin container-break() {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

@mixin make-header($color: false) {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;

  @if ($color) {
    color: $headings-color;
  }
}

@mixin triangle($direction, $size: 12px, $color: currentColor, $before: false) {
  $w: null;
  $h: null;
  $c: null;
  $length: length($size);
  $pseudo: if($before, 'before', 'after');

  @if $length > 2 {
    $w: nth($size, 1);
    $h: nth($size, 2);
    $c: nth($size, 3);
  } @else if $length > 1 {
    $w: nth($size, 1);
    $h: nth($size, 2);
    $c: nth($size, 2);
  } @else if $length == 1 {
    $w: nth($size, 1);
    $h: nth($size, 1);
    $c: nth($size, 1);
  } @else {
    @error "Invalid size \"#{$size}\"";
  }

  &:#{$pseudo} {

    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    vertical-align: middle;

    @if $direction == 'up' {

      @if ($length < 3) {

        border-bottom: $h solid $color;
        border-left: $w/2 solid transparent;
        border-right: $w/2 solid transparent;

      } @else {

        border-bottom: $h solid $color;
        border-left: $w solid transparent;
        border-right: $c solid transparent;

      }

    } @else if $direction == 'down' {

      @if ($length < 3) {

        border-top: $h solid $color;
        border-left: $w/2 solid transparent;
        border-right: $w/2 solid transparent;

      } @else {

        border-top: $h solid $color;
        border-left: $w solid transparent;
        border-right: $c solid transparent;

      }

    } @else if $direction == 'left' {

      @if ($length < 3) {

        border-right: $h solid $color;
        border-top: $w/2 solid transparent;
        border-bottom: $w/2 solid transparent;

      } @else {

        border-right: $h solid $color;
        border-top: $w solid transparent;
        border-bottom: $c solid transparent;

      }

    } @else if $direction == 'right' {

      @if ($length < 3) {

        border-left: $h solid $color;
        border-top: $w/2 solid transparent;
        border-bottom: $w/2 solid transparent;

      } @else {

        border-left: $h solid $color;
        border-top: $w solid transparent;
        border-bottom: $c solid transparent;

      }

    } @else {
      @error "Invalid direction \"#{$direction}\"";
    }

    @content;

  }

}
