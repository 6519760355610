.book-navigation {
  margin-top: $spacer;

  .pagination {
    justify-content: space-between;
    align-items: stretch;

    li {
      display: flex;
      align-items: stretch;

      a {
        display: flex;
        align-items: center;
      }
    }
  }
}
