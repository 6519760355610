.pane-front-vidjet {
  background: #fff9f1;
  //@include container-break();

  > .panel-inner {
    @extend .container;
  }

  .panel-display {
    transform: translateY(rem(-38px));
  }
}

#mini-panel-front_vidjet {
  > .panel-panel > div {
    @include lost-row();
    justify-content: space-around;
    align-items: stretch;
  }

  .panel-pane {
    display: flex;
    lost-column: 1/1 1 20px;

    + .panel-pane {
      margin-top: 20px;
    }

    @include media-breakpoint-up('lg') {
      lost-column: 1/3 3 20px;

      + .panel-pane {
        margin-top: 0;
      }
    }

    @include media-breakpoint-up('xl') {
      lost-column: 1/3;
    }

    .pane-content {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      width: 100%;
    }

    .pane-content {
      border: 1px solid #f9faf2;
      background: #fffcf8;
      box-shadow: 0 2px 6.44px 0.56px rgba(0, 0, 0, 0.1);
      padding: rem(19px);

      @include media-breakpoint-up('xl') {
        padding: rem(38px);
      }
    }

    .fieldable-panels-pane {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
    }

    .group-middle, .group-footer {
      width: 100%;
    }

    .group-footer {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include media-breakpoint-between('sm', 'md') {
        max-width: 520px;
        margin: 0 auto;

        ul {
          @include lost-row();
        }

        li {
          lost-column: 1/2 2 10px;
          list-style: none;

          &:before {
            font-awesome: circle;
            font-size: .25em;
            vertical-align: middle;
            margin-right: .5rem;
          }

          + li {
            margin-top: 0;
          }
        }
      }
    }
  }

  .field-name-field-readmore {
    text-align: left;

    @include media-breakpoint-down('sm') {
      text-align: center;
    }

    @include media-breakpoint-only('lg') {
      text-align: center;
    }
  }
}

.pane-bundle-icon-subtitle {
  .group-middle {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #ffb400;
    padding-bottom: rem(22px);
  }

  .group-left {
    width: 86px;
    padding-right: 16px;
    flex-shrink: 0;

    @include media-breakpoint-up('xl') {
      width: 104px;
    }

    img {
      max-width: 100%;
      height: auto !important;
    }
  }

  .block-title {
    line-height: (28 / 21);
    margin-bottom: .5rem;

    @include media-breakpoint-only('xs') {
      font-size: rem(16px);
    }
  }

  .field-name-field-title {
    font-size: rem(13px);
  }
}

.field-name-field-content {
  ul {
    padding: 20px 1.1rem;
  }

  li {
    + li {
      margin-top: 8px;
    }
  }
}
