#block-views-related-block {
  background-color: #fff9f1;
  padding-top: rem(40px);
  padding-bottom: rem(40px);

  @include media-breakpoint-up('sm') {
    padding-top: rem(70px);
    padding-bottom: rem(76px);
  }

  .block-content {
    @extend .container;
  }

  .field-name-field-related {
    @include lost-row();
    margin-bottom: (0 - $grid-gutter-width);

    > .node {
      margin-bottom: $grid-gutter-width;
      lost-column: 1/1;

      @include media-breakpoint-up('sm') {
        lost-column: 1/2 2 20px;
      }

      @include media-breakpoint-up('md') {
        lost-column: 1/2;
      }

      @include media-breakpoint-up('xl') {
        lost-column: 1/4;
      }
    }
  }

  .field-name-title h3 {
    letter-spacing: .01em;
    margin-bottom: rem(8px);
    line-height: 1.6;
    @include limit-lines(2);
    height: rem(60px);
  }

  .field-name-body {
    color: #3c3c3c;
  }

  .field-name-node-link {
    margin-top: rem(11px);
    text-align: left;
  }
}
