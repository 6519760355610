$anim-length: .4s;

body.panel-demo .page-header {
  lost-column: 12/12;

  @include media-breakpoint-up('lg') {
    lost-column: 8/12;
  }

  @include media-breakpoint-up('xl') {
    lost-column: 9/12;
  }

  color: #36835d;
  text-align: center;
  margin-top: rem(20px);
}

.pane-user-login {
  border: 1px solid #dceee5;
  border-radius: 2px;
  background-color: #f9fcfa;
  padding: rem(19px);
  max-width: 20rem;
  margin: 0 auto;

  .pane-title {
    font-size: rem(18px);
    text-align: center;
    line-height: (21 / 18);
    padding: rem(8px);
  }

  .form-control, .form-submit {
    border-radius: 2px;
  }

  ul {
    font-size: rem(11px);
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;
  }

  .form-submit {
    width: 100%;
    text-transform: uppercase;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  }
}

@keyframes demo-button-show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.paragraphs-item-demo-block {
  border: 1px solid #e6f4ed;
  background: #f9fcfa;
  border-radius: 2px;
  transition: all $anim-length ease-in-out;

  .content {
    position: relative;
    font-size: rem(13px);
  }

  .field-name-field-title {
    background: #e7f4eb;
    font-size: rem(16px);
    text-align: center;
    padding: 0 rem(50px);
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #21764b;
    @include limit-lines(2);
    position: relative;
    transition: all $anim-length ease-in-out;

    &:before {
      content: 'демо';
      text-transform: uppercase;
      position: absolute;
      top: 10px;
      left: 10px;
      display: block;
      background: #c3dace;
      font-weight: bold;
      font-size: rem(10px);
      color: #ffffff;
      width: 20em;
      transform-origin: 0 0;
      transform: rotateZ(-40deg) translate(-49%, 0);
      transition: all $anim-length ease-in-out;

      opacity: 0;
    }
  }

  .field-name-field-descr {
    opacity: 1;
    position: relative;
    z-index: 1;
    transition: opacity ($anim-length / 2) linear;
    padding: rem(10px) rem(19px);
    min-height: rem(42px + 20);
  }

  .field-button {
    padding: 0 rem(19px) rem(10px) rem(19px);

    .btn {
      margin: 0 auto;
      display: block;
      width: 100%;
      border: 1px solid #e2f5ed;
      border-radius: 2px;
      color: #11895c;
      text-shadow: none;
      transition: background-color $anim-length ease-in-out;
      background: #FFF linear-gradient(to bottom, #FFF, #e9f5f1);
      max-width: rem(220px);

      &:after {
        content: $white-space;
        display: inline-block;
        vertical-align: -10%;
        margin-left: .25rem;
        background: url("../img-src/images/icons/arrow.svg") no-repeat;
        $icon-size: 1em * (18 / 16);
        background-size: $icon-size $icon-size;
        width: $icon-size;
        height: $icon-size;
      }

      &:hover, &:focus {
        color: #FFF;
        border-color: #29cb8e;
        background: #2dd395 linear-gradient(to bottom, #2dd395, #1e7655);
      }
    }
  }

  &:hover {
    background-color: #f9fcfa;
    background-image: none;
    border-color: #c5e7d5;
    box-shadow: 0 3px 5.82px 0.18px rgba(0, 0, 0, 0.15);

    .field-name-field-title {
      background: #11895c;
      color: #FFF;

      &:before {
        color: #11895c;
        background: #FFF;
        opacity: 1;
      }
    }
  }
}

#panel-login {
  .panel-col-first .inside {
    @include lost-row();
    align-items: stretch;
    padding-bottom: 20px;

    > .panel-pane {
      lost-column: 12/12;

      @include media-breakpoint-up('sm') {
        lost-column: 6/12;
      }

      @include media-breakpoint-up('xl') {
        lost-column: 4/12;
      }
    }

    > .panel-pane, .pane-content {
      display: flex;
      width: 100%;
    }

    .paragraphs-item-demo-block {
      display: flex;
      flex-direction: column;
      width: 100%;

      > div {
        width: 100%;
      }

      .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
