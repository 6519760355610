.pane-reggis {
  //@include container-break();
  background: #fffcf9 url("../img-src/images/pane-reggis-pattern.jpg");
  padding-top: rem(65px);
  padding-bottom: rem(65px);

  > .panel-inner {
    @extend .container;
  }

  .panel-panel:first-child {
    margin-bottom: $spacer;
  }

  @include media-breakpoint-up('md') {
    .panel-2col {
      @include lost-row();

      .panel-panel:first-child {
        margin-bottom: 0;
      }

      > .panel-panel {
        lost-column: 1/2 2 20px;
      }
    }
  }

  @include media-breakpoint-up('lg') {
    .panel-2col {
      > .panel-panel {
        lost-column: 1/2;
      }
    }
  }

  .btn {
    margin-top: $spacer / 2;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
