#mini-panel-gisgkh_esia {
  > .panel-panel > div {
    @include media-breakpoint-up('lg') {
      @include lost-row();
      margin-bottom: -20px;
    }
  }

  .panel-pane {
    margin-bottom: 20px;

    @include media-breakpoint-up('lg') {
      lost-column: 6/12;
    }
  }
}

.pane-gisgkh-esia {
  @extend .container;
}

.pane-flagged {
  .panel-inner {
    position: relative;
    padding: 65px 0;

    @include media-breakpoint-up('sm') {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -16px;
        left: 0;
        z-index: 1;
        background: url('../img-src/images/flag.png') no-repeat;
        width: 200px;
        height: 76px;
        max-width: 100%;
        background-size: contain;
        pointer-events: none;

        @include media-breakpoint-up('lg') {
          top: -36px;
          width: width('../img-src/images/flag.png');
          height: height('../img-src/images/flag.png');
          left: 137px;
        }
      }
    }
  }

  .pane-content {
    position: relative;
    z-index: 2;
  }
}

.fieldable-panels-pane.view-mode-icon_separate {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up('sm') {
    flex-wrap: nowrap;
  }

  .group-left {
    flex-shrink: 0;
    display: block;
    margin-right: rem(16px);

    .field-name-field-header {
      img {
        height: auto !important;
      }

      width: 60px;

      @include media-breakpoint-up('xl') {
        width: 84px;
      }
    }
  }

  .block-title {
    margin-bottom: 1rem;

    @include media-breakpoint-down('sm') {
      font-size: rem(19px);
    }

    &:after {
      content: '';
      display: block;
      margin-top: 1rem;
      width: rem(80px);
      height: 2px;
      background: #218861;
    }
  }
}
