.header-row-2 {
  @extend .container;

  a.logo {
    display: block;
    padding: rem(16px) 0 rem(17px);
    margin-right: rem(15px);

    img {
      width: 116px;
    }
  }

  ul.navbar-nav {
    display: flex;
    justify-content: space-between;
    list-style: none none;
    padding: 0;

    > .nav-item + .nav-item {
      margin-left: rem(32px);

      @include media-breakpoint-up('xl') {
        margin-left: rem(46px);
      }
    }

    a {
      display: block;
    }

    a.active-trail {
      color: $navbar-light-active-color;
    }

    > li {
      > .nav-link {
        padding: rem(29px) 0;
        transition: border .2s ease-in-out;
        border-bottom: rem(3px) solid #FFF;

        @include hover-focus() {
          border-bottom: rem(3px) solid #1e7655;
        }
      }

      &.open {
        > .nav-link {
          color: $navbar-light-hover-color;
          border-bottom: rem(3px) solid #1e7655;
        }
      }
    }

    .dropdown-menu {
      margin: 0;
    }

    .dropdown-item {
      padding: 0;

      a {
        padding: rem(13px) rem(20px);

        &:hover, &:focus {
          color: $dropdown-link-hover-color;
        }
      }
    }

    .dropdown-item + .dropdown-item {
      border-top: 1px solid $dropdown-divider-bg;
    }
  }
}

.header-row-2 .col-2 .navbar {
  position: relative;
  min-height: (rem($ham-height) * 3 + rem($ham-space) * 2 + rem(20px));

  .navbar__nav {
    display: none;

    @include media-breakpoint-up('lg') {
      display: block;
    }
  }
}

@include media-breakpoint-down('md') {
  #page-wrap .slicknav_menu {
    display: block;
  }
}
