table, .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;

  &:last-child {
    margin-bottom: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  th {
    background: #faf4eb;

    h1, h2, h3, h4, h5, h6 {
      line-height: $line-height-base;
    }
  }

  th,
  td {
    padding: rem(13px) rem(28px);
    vertical-align: top;
    border-top: none;

    //&:first-child {
    //  padding-left: rem(63px);
    //}
    //
    //&:last-child {
    //  padding-right: rem(63px);
    //}
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  .table {
    background-color: $body-bg;
  }
}

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: transparent;
  }

  tbody tr:nth-of-type(even) {
    background-color: $table-accent-bg;
  }
}

.table-bordered {
  thead {
    th,
    td {
      border-bottom-width: $table-border-width;
    }
  }
}
