.pane-cms-footer {
  background: url("../img-src/images/cms-header-bg.jpg") 0 0 / cover no-repeat;

  > .panel-inner {
    background: rgba(0, 0, 0, .45);

    > .pane-content {
      @extend .container;
      padding-top: rem(90px);
      padding-bottom: rem(90px);
    }
  }

  .cms-header__row-1 {
    background-image: none;
    padding: 0;

    > .inner {
      padding: 0;
      background-color: transparent;
    }
  }

  #mini-panel-cms_footer {
    @include lost-row();

    .panel-col-first {
      order: 2;
      margin: rem(80px) auto 0;
      width: 100%;
      max-width: rem(300px);

      @include media-breakpoint-up('lg') {
        margin-top: 0;
        order: 1;
        lost-column: 1/4;
      }
    }

    .panel-col-last {
      order: 1;
      width: 100%;

      @include media-breakpoint-up('lg') {
        lost-column: 3/4;
        display: flex;
        justify-content: center;
        align-items: center;
        order: 2;
      }
    }
  }
}

.webform-client-form-49 {
  input {
    padding: rem(14px) rem(15px);
    border-radius: 2px;
    font-size: rem(16px);
  }

  .form-group {
    margin-bottom: rem(12px);
  }

  .form-actions {
    margin-top: rem(12px);

    .btn {
      width: 100%;
      cursor: pointer;
      color: #e9e9e9;
      font-size: rem(18px);
      padding: rem(13px) rem(15px);
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    }
  }
}
