::placeholder {
  font-style: italic;
}

.form-actions {
  margin-top: $spacer;
}

.form-text {
  margin-top: 0;
  font-size: rem(14px);
}
