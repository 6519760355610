.paragraphs-item-logo-link-text-images {
  @include lost-row();

  .group-left {
    width: 100%;
    text-align: center;
    margin-bottom: rem(40px);

    @include media-breakpoint-up('md') {
      lost-column: 1/4;
      margin-bottom: 0;
    }
  }

  .group-right {
    width: 100%;

    @include media-breakpoint-up('md') {
      lost-column: 3/4;
    }
  }

  .field-name-field-image {
    margin-bottom: rem(30px);

    img {
      margin: 0 auto;
    }
  }

  .field-name-field-link a {
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  .field-name-field-title {
    font-size: rem(18px);
    margin-bottom: rem(12px);
  }

  .field-name-field-images {
    margin-top: rem(30px);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: -20px;

    a {
      margin-bottom: 20px;
      display: block;
      border: 1px solid #cccccc;
      transition: border .2s ease-in-out;

      &:hover, &:focus {
        border-color: #00e976;
      }
    }

    @include media-breakpoint-up('sm') {
      flex-wrap: nowrap;

      a + a {
        margin-left: 40px;
      }
    }
  }
}
