.pane-presentations {
  padding-top: rem(68px);
  padding-bottom: rem(68px);

  > .panel-inner {
    @extend .container;
  }

  .panel-col {
    > div {
      @include lost-row();
      justify-content: space-around;
      margin-bottom: -20px;

      > .panel-pane {
        lost-column: 1/1 1 20px;
        margin-bottom: 20px;
        text-align: center;

        @include media-breakpoint-up('sm') {
          lost-column: 1/2 2 20px;
        }

        @include media-breakpoint-up('lg') {
          lost-column: 1/4 4 20px;
        }

        @include media-breakpoint-up('xl') {
          lost-column: 1/4;
        }
      }
    }
  }

  h3 {
    margin-bottom: .4rem;
  }

  a {
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: .5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
