html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#page-wrap {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main-container, .main-container-wide {
  flex-grow: 1;
  width: 100%;

  > .row {
    @include lost-row();
  }
}

.not-front {
  .main-container {
    @extend .container;
  }
}

.main-content {
  lost-column: 12/12;
}

.region-sidebar-first, .region-sidebar-second, .main-content {
  padding-bottom: rem(60px);
}

.no-sidebars {
  main {
    margin-top: $spacer;
  }
}

.sidebar-second {
  .main-container > .row {
    margin-top: $spacer;
  }

  .sidebar--second {
    lost-column: 12/12;

    @include media-breakpoint-up('lg') {
      lost-column: 4/12;
    }

    @include media-breakpoint-up('xl') {
      lost-column: 3/12;
    }
    //display: flex;
  }

  .main-content {
    lost-column: 12/12;

    @include media-breakpoint-up('lg') {
      lost-column: 8/12;
    }

    @include media-breakpoint-up('xl') {
      lost-column: 9/12;
    }
  }
}

body.panels-layout {
  #block-system-main > .block-content > .panel-2col {
    @include lost-row();
    padding-bottom: rem(40px);

    .panel-col-first {
      lost-column: 12/12;
    }
    .panel-col-last {
      lost-column: 12/12;
    }

    @include media-breakpoint-up('lg') {
      .panel-col-first {
        lost-column: 8/12;
      }
      .panel-col-last {
        lost-column: 4/12;
      }
    }

    @include media-breakpoint-up('xl') {
      .panel-col-first {
        lost-column: 9/12;
      }
      .panel-col-last {
        lost-column: 3/12;
      }
    }
  }
}
