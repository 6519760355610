.sidebar {
  .menu-block-wrapper > .nav {
    border: 1px solid #c9ecdb;
  }
  .nav {
    padding: 0;
    list-style: none none;

    a {
      display: block;
      padding: rem(13px) rem(20px);
      transition: all .2s ease-in-out;

      &.active, &.active-trail {
        color: #d49800;
      }

      @include hover-focus() {
        background: #fff9f1;
      }
    }

    li + li {
      border-top: 1px solid #c9ecdb;
    }

    ul {
      border-top: 1px solid #c9ecdb;
      background: #fafafa;

      a {
        padding-left: rem(30px);
      }

      ul a {
        padding-left: rem(40px);
      }
    }

    li.collapsed > a {
      &:after {
        font-awesome: angle-right;
        margin-left: .3em;
      }
    }

    li.expanded > a {
      &:after {
        font-awesome: angle-down;
        margin-left: .3em;
      }
    }
  }
}
