.nav-tabs {
  font-family: $headings-font-family;
  font-size: rem(13px);
  display: flex;
  flex-wrap: wrap;

  &:after {
    display: none;
  }

  .nav-item {
    flex-grow: 1;
    text-align: center;
    display: flex;
  }

  .nav-item + .nav-item {
    margin-left: rem(3px);
  }

  .nav-link {
    width: 100%;
    background: #f6f8fb;
    border-radius: 3px 3px 0 0;
    border: 1px solid $nav-tabs-border-color;
    border-bottom: 0;
    padding: rem(9px) 1rem;
    color: $body-color;

    &:hover, &:focus {
      background: #FFF;
    }

    &.active {
      background: #FFF;
      color: $nav-tabs-link-active-color;
    }
  }
}
