body {
  min-width: 300px;

  &.cke_editable {
    background-image: none;
    background-color: #FFF;
  }
}

img {
  @include img-fluid();
}

.nav-link {
  cursor: pointer;
}

.readmore {
  margin-top: .5rem;
  text-align: right;
}

.view-onecol {
  .views-row + .views-row {
    margin-top: $spacer;
  }
}

.field-name-body {
  overflow: auto;
  hyphens: auto;

  img {
    height: auto !important;
  }

  .more-link {
    margin-left: .5em;
  }

  .field-item > *:last-child {
    margin-bottom: 0;
  }
}

p:last-child, ul:last-child, ol:last-child {
  margin-bottom: 0;
}

.sidebar {
  .block + .block {
    margin-top: $spacer;
  }
}

.block-content {
  & > *:last-child {
    margin-bottom: 0;
  }
}

.fa {
  padding-right: .5em;
}

.svg-inline {
  display: inline-block;
  width: 1em;
  height: auto;
  vertical-align: -10%;
  margin-right: .5rem;
}

.feed-icon {
  display: block;
  text-align: right;
  margin-top: $spacer;
}

.tabs--primary {
  padding: 0;
  margin-bottom: $spacer;
}

ul, ol {
  padding-left: 1.5rem;
}

div#panels-ipe-control-container {
  left: 0;
}

.navbar-nav .dropdown-menu .nav-item + .nav-item {
  margin-left: 0;
}

.field-name-body {
  img[align="left"],
  img[style*="float: left"],
  img[style*="float:left"] {
    margin: 0 rem(40px) rem(16px) 0;
  }
  img[align="right"],
  img[style*="float: right"],
  img[style*="float:right"] {
    margin: 0 0 rem(16px) rem(40px);
  }
}

.field-name-more-link, .field-name-node-link {
  margin-top: $spacer / 2;
  text-align: right;
}

.form-managed-file {
  display: flex;

  .btn {
    margin-left: $spacer;
  }
}

caption {
  width: 100%;
}
