.pane-news-panel-pane-1 {
  @extend .container;
  padding-bottom: rem(95px);

  .pane-title {
    color: #4d4d4d;
    font-size: rem(15px);
    margin-bottom: .4rem;
  }

  @include media-breakpoint-up('xl') {
    padding-top: rem(36px);
    padding-bottom: rem(110px);
  }

  .view-content {
    @include lost-row();
  }

  .views-row {
    lost-column: 12/12 0 20px;

    @include media-breakpoint-up('lg') {
      lost-column: 1/2 2 20px;
    }

    @include media-breakpoint-up('xl') {
      lost-column: 1/2;
    }
  }

  .feed-icon {
    @include sr-only();
  }

  @include media-breakpoint-up('sm') {
    .views-row, .node, .group-footer {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
    }
  }

  .field-name-node-link {
    margin-top: auto;
  }
}
