#page-header .header-row-1 {
  @include media-breakpoint-down('sm') {
    .row {
      justify-content: center;
    }

    .col-1 {
      @include sr-only();
    }
  }
}

.header-row-1 {
  //@include container-break();
  background: #0b734c;
  color: #fafafa;
  font-size: rem(13px);
  line-height: (21 / 13);
  padding-top: rem(11px);
  padding-bottom: rem(11px);

  a {
    color: #fafafa;

    @include hover-focus() {
      text-decoration: underline;
    }
  }

  .block {
    display: inline-block;
    padding: 0 ($spacer / 2);

    @include media-breakpoint-up('sm') {
      padding: 0;

      & + .block {
        margin-left: rem(20px);

        @include media-breakpoint-up('lg') {
          margin-left: rem(38px);
        }
      }
    }
  }

  .menu {
    li {
      display: inline-block;
      padding: 1px rem(12px);
      line-height: 2em;

      &.last {
        padding-right: 0;
      }

      @include media-breakpoint-up('lg') {
        padding: 1px rem(30px);
      }

      @include media-breakpoint-only('xs') {
        & + li {
          margin-top: $spacer / 2;
        }
      }

      @include media-breakpoint-up('sm') {
        & + li {
          border-left: 2px solid #3c9876;
        }
      }
    }

    a.esia {
      &:before {
        content: $white-space;
        display: inline-block;
        background: url('../img-src/images/icons/esia-round.svg') 0 0 / contain no-repeat;
        width: 2em;
        height: 2em;
        margin-right: rem(9px);
      }
    }
  }
}

// Метрика
#block-block-5 {
  display: none;
}
