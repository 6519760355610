#mini-panel-footer_v2_0 {
  background: #044a30;
  border-top: 2px solid #2bc88d;
  border-bottom: 2px solid #2bc88d;
  padding: rem(55px) 0;
  color: #FFF;
  line-height: (30 / 15);
  //@include container-break();

  .row {
    margin-bottom: rem(-16px);
    margin-top: rem(-16px);
  }

  .panel-col {
    @include make-col-flex();
    padding-bottom: rem(16px);
    padding-top: rem(16px);
    flex-grow: 1;
  }

  @include media-breakpoint-up('lg') {
    .panel-col-4, .panel-col-1 {
      flex-basis: 36%;
      flex-grow: 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: #FFF;
  }

  a {
    color: #FFF;
    text-decoration: none;

    @include hover-focus() {
      text-decoration: underline;
    }
  }

  .pane-title {
    font-size: rem(17px);
    margin-bottom: rem(16px);
    color: #ffe8c3;
    text-transform: uppercase;
  }

  .pane-social {
    img {
      margin-right: 10px;
    }
  }

  .pane-logos {
    padding-top: rem(14px);

    .pane-content > div {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      margin-bottom: -15px;
    }

    img {
      margin-bottom: 15px;

      & + img {
        margin-left: 10px;
      }
    }
  }
}
